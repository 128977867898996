<template>
  <div>
    <v-app id="inspire">
      <v-img height="100vh" src="../../assets/Images/logo2.png">

        <v-layout wrap justify-center fill-height>
          <v-flex xs12 sm6 md4 lg3 px-2 align-self-center>
            <v-snackbar v-model="showSnackBar" color="black" right :timeout="timeout">
              <v-layout wrap justify-center>
                <v-flex text-left class="align-self-center">
                  <span style="color: white">
                    {{ msg }}
                  </span>
                </v-flex>
                <v-flex text-right>
                  <v-btn small :ripple="false" text @click="showSnackBar = false">
                    <v-icon style="color: white">mdi-close</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-snackbar>
            <v-layout wrap justify-center>
              <v-flex xs11 xl0>
                <v-card outlined color class="pa-4">
                  <v-layout wrap justify-center>
                    <v-flex>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-center>
                          <span style="
                font-family: mainfont;
                font-size: 20px;
                cursor: pointer;
                color: black;
              ">
                            FILM SHOOTING
                          </span>
                        </v-flex>

                        <v-flex xs12>
                          <v-layout wrap justify-center>
                            <v-flex xs6 pt-2 lg4 text-center>
                              <span style="
                    font-family: mainfont;
                    font-size: 15px;
                    color: #000;
                  ">
                                Log In
                              </span>
                              <v-progress-linear height="4" value="100" color="#314755"></v-progress-linear>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex pt-6 xs12 text-left>
                          <v-layout wrap justify-center>
                            <v-flex xs10 pt-4>
                              <v-form @submit.prevent="validateInput">
                                <v-text-field class="mainfont" color="#717171" placeholder="Email" outlined dense
                                  style="font-family: mainfont" v-model="emailId" hide-details>
                                </v-text-field>
                              </v-form>
                            </v-flex>
                            <v-flex xs10 pt-4>
                              <v-form @submit.prevent="validateInput">
                                <v-text-field color="#717171" placeholder="Password" outlined dense :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'
                                  " :type="show3 ? 'text' : 'password'" name="input-10-2"
                                  @click:append="show3 = !show3" style="font-family: mainfont" v-model="password"
                                  hide-details>
                                </v-text-field>
                              </v-form>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs10 pt-8 pb-2>
                          <v-btn class="no-uppercase mainfont" dark block color="#314755" @click="validateInput">
                            LOGIN
                          </v-btn>
                        </v-flex>
                        <v-flex style="cursor: pointer;" xs10 pt-4 pb-8 text-center @click="$router.push('/register')">
                          <span style="font-family: mainfont;">New user? Click here to register</span>
                        </v-flex>
                        <v-flex style="cursor: pointer;" xs10 pb-1 text-center @click="$router.push('/forgotpassword')">
                          <span style="font-family: mainfont;">Forgot Password?</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-img>

    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import store from "./../../store";
import CryptoJS from "crypto-js";
export default {
  data() {
    return {
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      emailId:null,
      otp: null,
      phone: null,
      username: null,
      password: null,
      show1: false,
      show2: true,
      show3: false,
      show4: false,
    };
  },
  mounted() {
    this.phone = this.$route.query.phone;
  },
  methods: {
    validateInput() {
      if (!this.emailId) {
        this.msg = "Please Provide OTP";
        this.showSnackBar = true;
        return;
      } else {
        this.login();
      }
    },
    login() {
      const encryptedEmail = CryptoJS.AES.encrypt(this.emailId, 'film#$%^(*&!#_:"<>shoot').toString();
      const encryptedPassword = CryptoJS.AES.encrypt(this.password, 'film#$%^(*&!#_:"<>shoot').toString();
      axios({
        method: "post",
        url: "/user/login",
        data: {
          emailId: encryptedEmail,
          password: encryptedPassword,
        },
      })
        .then((response) => {
          if (response.data.status) {
            store.commit("loginUser", response.data.token);
            // store.commit("id", response.data.data._id);
            var uType = response.data.data.uType;
            var path = response.data.data.path;
            localStorage.setItem("uType", uType);
            localStorage.setItem("path", path);
            this.$store.commit("email", response.data.data.emailId);

            // if (uType === 777) {
            //   this.$router.push({ path: "/DashboardPage" });
            // }
            // else 
            if (uType === 772) {
              store.commit("loginUser", response.data.token);
              this.$router.push({ path: "/mybooking" });
            }
            else if (uType === 773) {
              store.commit("loginUser", response.data.token);
              this.$router.push({ path: "/rangebookings" });
            }
            else if (uType === 7741) {
              store.commit("loginUser", response.data.token);
              this.$router.push({ path: "/sectionhome" });
            }

            else if (uType === 7742) {
              store.commit("loginUser", response.data.token);
              this.$router.push({ path: "/superintedent" });
            }
            else {
              this.$router.push({ path: "/divisiondashboard" });
            }
            location.reload()
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
   
